import React, { useEffect, useState } from "react";
// import { AwsUpdate } from "../../../utils/tools"
import ImageCropper from "../ImageCropper";
import { AwsUpdate } from "../../../utils/tool";

export default function FileUploader(props) {
    // useRef
    const [uploading, setUploading] = useState(false) // 上传中
    const [uploadPercent, setUploadPercent] = useState(0) // 上传进度
    // const [uploadImg, setUploadImg] = useState('https://picx.zhimg.com/v2-cba0b89d2bf2d96a1ed26edb5849f804_qhd.jpg?source=172ae18b') // 上传之后的图片地址
    const [uploadImg, setUploadImg] = useState('') // 上传之后的图片地址
    const [showCropModal, setShowCropModal] = useState(false) // 是否展示裁剪框
    const [cropperImagePath, setCropperImagePath] = useState('') // 裁剪框的图片地址

    useEffect(() => {
        if (props.url && props.url !== uploadImg) {
            console.log('props', props)
            setUploadImg(props.url)
        }
    }, [props, uploadImg])

    const handleUploadLogic = (file, k) => {
        return new Promise((resolve, reject) => {
            setUploadImg('')
            setUploading(true)
            AwsUpdate(file, k, (x) => {
                const percent = Math.round((x.loaded / x.total) * 10000) / 100;
                console.log("上传进度是", percent, "%");
                setUploadPercent(percent)
            }).then((x) => {
                console.log("上传之后的结果是", x);
                setUploadImg(x.Location)
                setUploading(false)
                props.onFileUpload && props.onFileUpload(x.Location)
                resolve(x.Location)
            }).catch(e => {
                console.log('上传失败', e)
                setUploading(false)
                reject(e)
            })
        })

    }
    const handleFileInput = (e) => {
        // handle validations
        const file = e.target.files[0]

        if (props.onBeforeUpload) {
            if (!props.onBeforeUpload(file)) {
                return
            }
        }

        // 将文件变成url传递给裁剪框
        const cropperImagePath = URL.createObjectURL(file);
        setCropperImagePath(cropperImagePath)
        setShowCropModal(true)
        // return
        // let k = Date.now() + "" + file.name;
        // console.log(e.target.files[0])
        // // 展示裁剪框

        // props.onFileSelect && props.onFileSelect(file)

    }

    return (<>
        <div style={{ 'width': props.width, 'minHeight': props.height }} className="stake-file-uploader">
            <input
                type="file" onChange={handleFileInput} />
            {!uploadImg && <div className="stake-file-uploader-box">
                {uploading && <div className="stake-file-uploader-box-loading">
                    <div>
                        <i style={{ 'fontSize': '20px' }} className="fa fa-spinner fa-spin fa-3x fa-fw"></i>
                    </div>
                    <div>
                        {uploadPercent}%
                    </div>

                </div>}

                {!uploading && '+'}
            </div>}
            {uploadImg && <div className="stake-file-uploader-box">
                <img src={uploadImg} className="stake-file-uploader-box-img" alt="" />
            </div>}
        </div>
        <ImageCropper image={cropperImagePath} show={showCropModal}
            handleClose={() => {
                setShowCropModal(false)
            }}
            ok={(file) => {
                setShowCropModal(false)
                // 上传
                let k = Date.now() + ".jpg";
                handleUploadLogic(file, k)
                    .then(url => {
                        props.ok && props.ok(url)
                    })

            }}
        />

    </>)
}