import { useState } from 'react'
import { Form, Input, Select, Button, message, Modal, Alert, Radio } from 'antd'
import UploadImage from './components/UploadImage'
import { CopyOutlined, RightOutlined } from '@ant-design/icons'
import { Connection, PublicKey, clusterApiUrl, SystemProgram, Keypair, ComputeBudgetProgram } from '@solana/web3.js'
import { AnchorProvider, BN, Program, web3 } from '@project-serum/anchor'
import { getIpfsUrl, uploadText } from '../utils/ipfs'
import idl from '../idl.json'
import { useSelector } from 'react-redux';
import { ethers } from 'ethers'
import { getAssociatedTokenAddress, TOKEN_PROGRAM_ID } from '@solana/spl-token'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const programID = new PublicKey('5cAYrw5nrC9qc1EXLhkZmwqJiviRzuRF1vx2ezy1EB8a')

function CreateSolanaTokenPage() {
    // const history = useHistory()
    const [messageApi, contextHolder] = message.useMessage()
    const [tokenInfo, setTokenInfo] = useState({
        name: '',
        symbol: '',
        decimals: '9',
        logo: '',
        totalSupply: '',
        template: 1,
        intro: '',
        website: '',
        twitter: '',
        telegram: '',
    })
    const { t } = useTranslation()
    const [mint, setMint] = useState(null)
    const [showSubmitDialog, setShowSubmitDialog] = useState(false)
    const [submitLoading, setSubmitLoading] = useState(false)
    const [errMsg, setErrMsg] = useState('')
    const [submitStatus, setSubmitStatus] = useState(false)  // 创建是否成功, 如果成功了要给提示
    const [submitTxHash, setSubmitTxHash] = useState('')  // 创建成功后的交易哈希
    const [currentNetwork, setCurrentNetwork] = useState('mainnet-beta') // 选择当前网络
    const fee = 180000000

    const walletAddress = useSelector(state => state.network.wallet)
    const validParams = () => {
        if (!walletAddress) {
            messageApi.error(t('请先登录'))
            return false
        }
        if (!tokenInfo.name) {
            messageApi.error(t('请填写全称'))
            return false
        }
        if (!tokenInfo.symbol) {
            messageApi.error(t('请填写简称'))
            return false
        }
        if (!tokenInfo.logo) {
            messageApi.error(t('请上传logo'))
            return false
        }
        if (!tokenInfo.decimals) {
            messageApi.error(t('请填写精度'))
            return false
        }
        if (!tokenInfo.totalSupply) {
            messageApi.error(t('请填写总量'))
            return false
        }
        return true
    }
    const generateMint = () => {
        // 生成mint
        const m = Keypair.generate()
        setMint(m)
    }
    const submit = () => {
        // 1. 验证填写的信息
        if (!validParams()) return

        // 2. 生成mint
        generateMint()
        // 3. 弹出确认框
        setShowSubmitDialog(true)
    }

    const getProvider = () => {
        let url = 'https://dry-sparkling-road.solana-mainnet.quiknode.pro/9155fd5ad8287d2247fb4cde578fbf9ce2c42f1b/'
        if (currentNetwork === 'devnet') {
            url = clusterApiUrl(currentNetwork)
        }
        const connection = new Connection(url, 'confirmed')
        const provider = new AnchorProvider(connection, window.solana, {
            preflightCommitment: 'confirmed',
            commitment: 'confirmed',
        })
        return provider
    }
    const submitLogic = async () => {
        // 上传metadata信息到ipfs
        // 调用方法提交到链上
        const matadata = {
            name: tokenInfo.name,
            symbol: tokenInfo.symbol,
            image: tokenInfo.logo,
            description: tokenInfo.intro,
            extensions: {
                telegram: tokenInfo.telegram,
                twitter: tokenInfo.twitter,
                website: tokenInfo.website
            }
        }
        setErrMsg('')
        setSubmitLoading(true)
        try {
            const ipfsRes = await uploadText(JSON.stringify(matadata))
            const uri = getIpfsUrl(ipfsRes.path)
            console.log(uri)
            // 调用合约
            const provider = getProvider()
            const program = new Program(idl, programID, provider)
            const tt = ethers.utils.parseUnits(tokenInfo.totalSupply + '', parseInt(tokenInfo.decimals))

            const tInfo = {
                name: tokenInfo.name,
                symbol: tokenInfo.symbol,
                uri: uri,
                decimals: parseInt(tokenInfo.decimals),
                totalSupply: new BN(tt.toString()),
                fee: new BN(fee),
            }
            const treasury = new PublicKey('H1HPMXDAPs5oU72hfN2xbi2FHbkeNMq5H9mPRKyNdaJr')
            const METADATA_SEED = 'metadata';
            const TOKEN_METADATA_PROGRAM_ID = new web3.PublicKey("metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"); // 元数据工厂合约
            const a = new PublicKey("ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL") // 创建关联账户的合约
            const tokenAccount = await getAssociatedTokenAddress(mint.publicKey, provider.wallet.publicKey);
            const [metadataAddress, metadataBumps] = web3.PublicKey.findProgramAddressSync(
                [
                    Buffer.from(METADATA_SEED),
                    TOKEN_METADATA_PROGRAM_ID.toBuffer(),
                    mint.publicKey.toBuffer(),
                ],
                TOKEN_METADATA_PROGRAM_ID
            );

            const t1 = ComputeBudgetProgram.setComputeUnitPrice({
                microLamports: 50000
            })
            const t2 = ComputeBudgetProgram.setComputeUnitLimit({
                units: 1000000
            })

            const tx = await program.methods.create(tInfo).accounts({
                payer: provider.wallet.publicKey,
                mint: mint.publicKey,
                tokenAccount: tokenAccount,
                mintAuthority: provider.wallet.publicKey,
                tokenProgram: TOKEN_PROGRAM_ID,
                rent: web3.SYSVAR_RENT_PUBKEY,
                treasury: treasury,
                systemProgram: web3.SystemProgram.programId,
                associatedTokenProgram: a,
                metadata: metadataAddress,
                tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
            }).preInstructions([t1, t2]).signers([mint]).rpc()
            console.log(tx)
            const logs = await provider.connection.getTransactions([tx], 'confirmed');
            console.log(logs, '创建成功')
            setSubmitLoading(false)
            setSubmitStatus(true)
            setSubmitTxHash(tx)

            messageApi.success(t('创建成功'))
        } catch (e) {
            console.log(e)
            setErrMsg(e.message)
            setSubmitLoading(false)
        }



    }

    const showTx = () => {
        const url = `https://solscan.io/tx/${submitTxHash}${currentNetwork === 'devnet' ? '?cluster=devnet' : ''}`
        window.open(url, '_blank')
    }
    return <div >
        {contextHolder}

        <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} >
            {/* <Form.Item wrapperCol={{ offset: 4, span: 14 }} >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <Button type='link' >
                        <Link to={'/burn'}>
                            代币/流动性销毁工具
                        </Link>
                        <RightOutlined />
                    </Button>

                </div>

            </Form.Item> */}
            <Form.Item label={t('全称')}>
                <Input value={tokenInfo.name} onChange={e => {
                    setTokenInfo({ ...tokenInfo, name: e.target.value })
                }} />
            </Form.Item>
            <Form.Item label={t('简称')}>
                <Input value={tokenInfo.symbol} onChange={e => {
                    setTokenInfo({ ...tokenInfo, symbol: e.target.value })
                }} />
            </Form.Item>
            <Form.Item label="Logo">
                <UploadImage ok={url => {
                    setTokenInfo({ ...tokenInfo, logo: url })
                }} />
            </Form.Item>
            <Form.Item label={t('精度')}>
                <Input value={tokenInfo.decimals} onChange={e => {
                    setTokenInfo({ ...tokenInfo, decimals: e.target.value })
                }} />
            </Form.Item>
            <Form.Item label={t('总量')}>
                <Input value={tokenInfo.totalSupply} onChange={e => {
                    setTokenInfo({ ...tokenInfo, totalSupply: e.target.value })
                }} />
            </Form.Item>
            <Form.Item label={t('选择模版')}>
                <Select
                    defaultValue={1}
                    onChange={e => {
                        console.log(e)
                        setTokenInfo({ ...tokenInfo, template: e })

                    }}
                    options={[
                        { value: 1, label: 'SPL Token' },
                        // { value: 2, label: 'SPL Token 2022' },
                    ]}
                />
            </Form.Item>
            <Form.Item label={t('简介')}>
                <Input.TextArea placeholder={t('(选填)500字以内')} rows={4} value={tokenInfo.intro} onChange={e => {
                    setTokenInfo({ ...tokenInfo, intro: e.target.value })
                }} />
            </Form.Item>
            <Form.Item label={t('官网')}>
                <Input placeholder={t('(选填)https')} value={tokenInfo.website} onChange={e => {
                    setTokenInfo({ ...tokenInfo, website: e.target.value })
                }} />
            </Form.Item>
            <Form.Item label={t('推特')}>
                <Input placeholder={t('(选填)https')} value={tokenInfo.twitter} onChange={e => {
                    setTokenInfo({ ...tokenInfo, twitter: e.target.value })
                }} />
            </Form.Item>
            <Form.Item label={t('电报')}>
                <Input placeholder={t('(选填)https')} value={tokenInfo.telegram} onChange={e => {
                    setTokenInfo({ ...tokenInfo, telegram: e.target.value })
                }} />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 4, span: 14 }}>
                <Button onClick={submit} type='primary'>{t('确认创建')}</Button>
                <div style={{ color: '#1677ff', fontSize: '12px', marginTop: '10px' }}>
                    {t('手续费')}: {fee / 1e9} SOL
                </div>
            </Form.Item>
        </Form>

        <Modal title={t('创建SPL代币')} open={showSubmitDialog} onOk={() => {
            setShowSubmitDialog(false)
        }} onCancel={() => {
            setShowSubmitDialog(false)
        }} footer={null} >
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} >
                <Form.Item label={t('代币地址')}>
                    {mint && mint.publicKey.toString()} <CopyOutlined onClick={() => {
                        navigator.clipboard.writeText(mint.publicKey.toString())
                        messageApi.success(t('复制成功'))
                    }} />
                </Form.Item>
                <Form.Item label={t('部署网络')}>
                    <Radio.Group onChange={e => {
                        setCurrentNetwork(e.target.value)
                    }} value={currentNetwork}>
                        <Radio value={'mainnet-beta'}>{t('Solana主网')}</Radio>
                        <Radio value={'devnet'}>{t('Solana开发网')}</Radio>
                    </Radio.Group>
                </Form.Item>
                {errMsg && <>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Alert
                            style={{ width: '100%' }}
                            message={t('错误')}
                            description={errMsg}
                            type="error"
                            closable={false}
                        />
                    </Form.Item>
                </>}
                {submitStatus ? <>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Alert
                            style={{ width: '100%' }}
                            message={t('创建成功')}
                            description={t('可在钱包中查看')}
                            type="success"
                            closable={false}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={showTx} type='primary'>{t('查看交易哈希详情')}</Button>
                        <Button onClick={() => {
                            setShowSubmitDialog(false)
                        }} style={{ marginLeft: '10px' }} type='primary' danger>{t('关闭')}</Button>
                    </Form.Item>
                </> : <>
                    <Form.Item>
                        <Button loading={submitLoading} onClick={submitLogic} type='primary'>{t('确认')}</Button>
                        <Button onClick={() => {
                            setShowSubmitDialog(false)
                        }} style={{ marginLeft: '10px' }} type='primary' danger>{t('取消')}</Button>
                    </Form.Item>
                </>}
            </Form>

        </Modal>
    </div>
}

export default CreateSolanaTokenPage