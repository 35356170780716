
import { PublicKey, Connection, clusterApiUrl, Keypair } from '@solana/web3.js'
import { AnchorProvider } from '@project-serum/anchor'
import bs58 from 'bs58'

export const getShortAddrss = (address) => {
    if (address) {
        return address.substr(0, 6) + '...' + address.substr(-4)
    }
    return ''
}


export const isValidSolanaAddress = (address) => {
    try {
        // 尝试创建一个PublicKey实例，如果地址无效，将抛出错误
        new PublicKey(address);
        return true;
    } catch (error) {
        // 如果出现错误，地址无效
        return false;
    }
}

export const getNetworkConnection = (currentNetwork) => {
    let url = 'https://dry-sparkling-road.solana-mainnet.quiknode.pro/9155fd5ad8287d2247fb4cde578fbf9ce2c42f1b/'
    if (currentNetwork === 'devnet') {
        url = clusterApiUrl(currentNetwork)
    }
    const connection = new Connection(url);
    return connection
}

export const getSolanaProvider = (currentNetwork) => {
    const connection = getNetworkConnection(currentNetwork)
    const provider = new AnchorProvider(connection, window.solana, {
        preflightCommitment: 'confirmed',
        commitment: 'confirmed',
    })
    return provider
}


export const getShortAddress = (address) => {
    // return address
    if (address) {
        return address.substr(0, 6) + '...' + address.substr(-4)
    }
    return ''

}

export const getKeypairFromString = (secretKeyString) => {
    let decodedSecretKey
    try {
        // Keypair.base58ToSecretKey(secretKeyString);
        decodedSecretKey = bs58.decode(secretKeyString);
    } catch (throwObject) {
        throw new Error("Invalid secret key! See README.md");
    }
    return Keypair.fromSecretKey(decodedSecretKey);
};

export const getTokenInfo = async (tokenAddress, connection) => {
    try {
        const mintPublicKey = new PublicKey(tokenAddress)
        // const connection = getConnection()

        const info = await connection.getTokenSupply(mintPublicKey)

        const { amount, decimals, uiAmount } = info.value

        return {
            amount,
            decimals,
            uiAmount,
            address: tokenAddress
        }
    } catch (e) {
        console.log(e)
        throw new Error(e.message)
    }

}



var AWS = require('aws-sdk');

var albumBucketName = "token-monitor";
var bucketRegion = "us-east-1";


AWS.config.region = bucketRegion; // 区域
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'us-east-1:9d54ee94-24a7-4c6f-ba16-a35a515b5465',
});


export function AwsUpdateText(text, key = '') {
    var s3 = new AWS.S3();
    var params = {
        Bucket: albumBucketName,
        Key: key,
        Body: text,
        ContentType: 'text/plain'
    };
    return s3.upload(params).promise()
}

// var s3 = new AWS.S3();
export function AwsUpdate(file, key = '', onProgressCb = null) {
    console.log(file)
    var upload = new AWS.S3.ManagedUpload({
        params: {
            Bucket: albumBucketName,
            Key: key,
            Body: file
        }
    });

    upload.on('httpUploadProgress', (x) => {
        // const percent = Math.round(x.loaded / x.total * 10000) / 100
        // console.log('上传进度是', percent, "%")
        onProgressCb && onProgressCb(x)
    })

    var promise = upload.promise();
    return promise
}