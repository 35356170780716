import { useEffect, useState } from 'react';
import { Form, Radio, Input, Button, message, Modal, Progress, Alert, Card, Table } from 'antd'
import AvatarEditor from 'react-avatar-editor'
import ImageCropper from './components/ImageCropper';
import FileUploader from './components/FileUploader';
import { set } from '@project-serum/anchor/dist/cjs/utils/features';
import { AwsUpdateText } from '../utils/tool';

const CONFIG_FILE_KEY = 'https://token-monitor.s3.amazonaws.com/gcsq-config.json'


function SetConfigPage() {

    const [submitLoading, setSubmitLoading] = useState(false);
    const [contextHolder, setContextHolder] = useState(null);

    const [tokenList, setTokenList] = useState([]) // 热门代币列表
    const [cropperImagePath, setCropperImagePath] = useState('') // 裁剪框的图片地址
    const [showCropModal, setShowCropModal] = useState(false) // 是否展示裁剪框

    const [showTokenModal, setShowTokenModal] = useState(false) // 是否显示新增代币弹窗

    const [modal, modalContextHolder] = Modal.useModal();

    const [tmpTokenInfo, setTmpTokenInfo] = useState({
        avatar: '',
        name: '',
        contract: '',
        tgId: '',
        tgLink: '',
        desc: '',
        totalSupply: '',
        buyTax: '',
        sellTax: '',
        openTime: '',
        twitter: '',
        qq: ''
    }) // 临时代币信息

    const [updateTokenIndex, setUpdateTokenIndex] = useState(-1) // 更新的代币索引

    const [topTokenInfo, setTopTokenInfo] = useState({
        avatar: '',
        name: '',
        contract: '',
        tgId: '',
        tgLink: '',
        desc: '',
        totalSupply: '',
        buyTax: '',
        sellTax: '',
        openTime: '',
        twitter: '',
        qq: '',
        contactTgLink: '',
        contactTgId: ''
    }) // 头部代币信息


    useEffect(() => {
        fetch(CONFIG_FILE_KEY).then(res => res.json()).then(data => {

            console.log('data', data)
            setTopTokenInfo(data.top)
            setTokenList(data.list)
        })
    }, [])


    const submit = async () => {
        setSubmitLoading(true)
        console.log('提交', topTokenInfo, tokenList)
        // 提交数据
        // 提交成功
        let data = {
            top: topTokenInfo,
            list: tokenList
        }
        const d = await AwsUpdateText(JSON.stringify(data), 'gcsq-config.json')
        console.log('dddd', d)
        message.success('提交成功')
        setSubmitLoading(false)
    }

    return (
        <div>
            {contextHolder}
            {modalContextHolder}
            {/* <Form > */}

            <Card title="头部代币信息">
                <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} >
                    <Form.Item label='名称'>
                        <Input value={topTokenInfo.name} onChange={e => {
                            setTopTokenInfo({ ...topTokenInfo, name: e.target.value })
                        }} />
                    </Form.Item>
                    <Form.Item label='合约'>
                        <Input placeholder='0x...0000' value={topTokenInfo.contract} onChange={e => {
                            setTopTokenInfo({ ...topTokenInfo, contract: e.target.value })
                        }} />
                    </Form.Item>
                    {/* <Form.Item label='TG群ID'>
                        <Input placeholder='@xxxx' value={topTokenInfo.tgId} onChange={e => {
                            setTopTokenInfo({ ...topTokenInfo, tgId: e.target.value })
                        }} />
                    </Form.Item> */}
                    <Form.Item label='TG群链接'>
                        <Input placeholder='https://t.me/xxxx' value={topTokenInfo.tgLink} onChange={e => {
                            setTopTokenInfo({ ...topTokenInfo, tgLink: e.target.value })
                        }} />
                    </Form.Item>
                    <Form.Item label='发行总量'>
                        <Input rows={5} value={topTokenInfo.totalSupply} onChange={e => {
                            setTopTokenInfo({ ...topTokenInfo, totalSupply: e.target.value })
                        }} />
                    </Form.Item>
                    {/* 买入税点 */}
                    <Form.Item label='买入税点'>
                        <Input rows={5} value={topTokenInfo.buyTax} onChange={e => {
                            setTopTokenInfo({ ...topTokenInfo, buyTax: e.target.value })
                        }} />
                    </Form.Item>
                    {/* 卖出税点 */}
                    <Form.Item label='卖出税点'>
                        <Input rows={5} value={topTokenInfo.sellTax} onChange={e => {
                            setTopTokenInfo({ ...topTokenInfo, sellTax: e.target.value })
                        }} />
                    </Form.Item>
                    {/* 开盘时间 */}
                    <Form.Item label='开盘时间'>
                        <Input rows={5} value={topTokenInfo.openTime} onChange={e => {
                            setTopTokenInfo({ ...topTokenInfo, openTime: e.target.value })
                        }} />
                    </Form.Item>
                    {/* 推特 */}
                    <Form.Item label='推特'>
                        <Input rows={5} value={topTokenInfo.twitter} onChange={e => {
                            setTopTokenInfo({ ...topTokenInfo, twitter: e.target.value })
                        }} />
                    </Form.Item>
                    {/* QQ */}
                    <Form.Item label='QQ'>
                        <Input rows={5} value={topTokenInfo.qq} onChange={e => {
                            setTopTokenInfo({ ...topTokenInfo, qq: e.target.value })
                        }} />
                    </Form.Item>
                    <Form.Item label='简介'>
                        <Input.TextArea rows={5} value={topTokenInfo.desc} onChange={e => {
                            setTopTokenInfo({ ...topTokenInfo, desc: e.target.value })
                        }} />
                    </Form.Item>
                    {/* 广告位联系人TG ID */}
                    <Form.Item label='联系人TG ID'>
                        <Input value={topTokenInfo.contactTgId} onChange={e => {
                            setTopTokenInfo({ ...topTokenInfo, contactTgId: e.target.value })
                        }
                        } />
                    </Form.Item>
                    {/* 广告位联系人TG 链接 */}
                    <Form.Item label='联系人TG 链接'>
                        <Input value={topTokenInfo.contactTgLink} onChange={e => {
                            setTopTokenInfo({ ...topTokenInfo, contactTgLink: e.target.value })
                        }
                        } />
                    </Form.Item>
                </Form>
            </Card>

            <Card title="热门代币信息">

                <Button style={{ marginBottom: '20px' }} type='primary' variant="solid" onClick={() => {
                    setShowTokenModal(true)
                }}>新增代币</Button>
                {/* 这里是个表格 */}
                <Table dataSource={tokenList} columns={[
                    {
                        title: '头像',
                        dataIndex: 'avatar',
                        render: (avatar) => {
                            return <img src={avatar} style={{ width: '50px', height: '50px' }} />
                        }
                    },
                    {
                        key: 'name',
                        title: '名称',
                        dataIndex: 'name'
                    },
                    {
                        key: 'contract',
                        title: '合约',
                        dataIndex: 'contract'
                    },
                    // {
                    //     title: 'TG群ID',
                    //     dataIndex: 'tgId'
                    // },
                    // {
                    //     title: 'TG群链接',
                    //     dataIndex: 'tgLink'
                    // },
                    {
                        title: '操作',
                        render: (record) => {

                            return <div>
                                <Button type='primary' size='small' onClick={() => {
                                    console.log('编辑', record)

                                    tokenList.forEach(item => {
                                        if (item === record) {
                                            const ind = tokenList.indexOf(item)
                                            setUpdateTokenIndex(ind)
                                            setTmpTokenInfo({ ...record })
                                            setShowTokenModal(true)
                                        }
                                    })
                                }}>编辑</Button>
                                <Button type='link' size='small' onClick={async () => {
                                    console.log('删除', record)
                                    // 弹出确认删除框
                                    const c1 = await modal.confirm({
                                        title: '确认删除?',
                                    })
                                    console.log('c1', c1)
                                    if (c1) {
                                        // 删除
                                        setTokenList(tokenList.filter(item => item !== record))
                                    }

                                }}>删除</Button>
                                <Button type='link' size='small' onClick={() => {
                                    console.log('上移', record)
                                    const ind = tokenList.indexOf(record)
                                    if (ind === 0) {
                                        message.error('已经是第一个了')
                                        return
                                    }
                                    const list = [...tokenList]
                                    const tmp = list[ind]
                                    list[ind] = list[ind - 1]
                                    list[ind - 1] = tmp
                                    setTokenList(list)
                                }}>上移</Button>
                                <Button type='link' size='small' onClick={() => {
                                    console.log('下移', record)
                                    const ind = tokenList.indexOf(record)
                                    if (ind === tokenList.length - 1) {
                                        message.error('已经是最后一个了')
                                        return
                                    }
                                    const list = [...tokenList]
                                    const tmp = list[ind]
                                    list[ind] = list[ind + 1]
                                    list[ind + 1] = tmp
                                    setTokenList(list)
                                }}>下移</Button>

                            </div>
                        }
                    }
                ]} />

            </Card >

            <Form>
                <Form.Item style={{ marginTop: '20px' }}>

                    <Button type='primary' loading={submitLoading} onClick={submit}>提交</Button>
                    <Button type='link' onClick={() => {
                        setTopTokenInfo({
                            name: '',
                            contract: '',
                            tgId: '',
                            tgLink: '',
                            desc: ''
                        })
                        setTokenList([])
                    }}>清空</Button>
                </Form.Item>
            </Form>

            <Modal
                title="新增代币"
                open={showTokenModal}
                onOk={() => {
                    // 验证信息
                    if (!tmpTokenInfo.avatar) {
                        message.error('请上传头像')
                        return
                    }
                    if (!tmpTokenInfo.name) {
                        message.error('请填写名称')
                        return
                    }
                    if (!tmpTokenInfo.contract) {
                        message.error('请填写合约地址')
                        return
                    }
                    // if (!tmpTokenInfo.tgId) {
                    //     message.error('请填写TG群ID')
                    //     return
                    // }
                    // if (!tmpTokenInfo.tgLink) {
                    //     message.error('请填写TG群链接')
                    //     return
                    // }
                    // 将当前的数据设置到tokenList中

                    if (updateTokenIndex !== -1) {
                        // 上传
                        const list = [...tokenList]
                        list[updateTokenIndex] = tmpTokenInfo
                        setTokenList(list)
                    } else {
                        setTokenList([...tokenList, tmpTokenInfo])
                    }


                    setShowTokenModal(false)
                }}
                onCancel={() => {
                    setShowTokenModal(false)
                }}
            >
                <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} >
                    <Form.Item label="头像">
                        <FileUploader url={tmpTokenInfo.avatar} width="100px" height="100px" ok={(url) => {
                            console.log('上传的链接为:', url)
                            setTmpTokenInfo({ ...tmpTokenInfo, avatar: url })
                        }} />
                    </Form.Item>
                    <Form.Item label='名称'>
                        <Input value={tmpTokenInfo.name} onChange={e => {
                            setTmpTokenInfo({ ...tmpTokenInfo, name: e.target.value })
                        }} />
                    </Form.Item>
                    <Form.Item label='合约'>
                        <Input placeholder='0x...0000' value={tmpTokenInfo.contract} onChange={e => {
                            setTmpTokenInfo({ ...tmpTokenInfo, contract: e.target.value })
                        }} />
                    </Form.Item>
                    {/* <Form.Item label='TG群ID'>
                        <Input placeholder='(选填)@xxxx' value={tmpTokenInfo.tgId} onChange={e => {
                            setTmpTokenInfo({ ...tmpTokenInfo, tgId: e.target.value })
                        }} />
                    </Form.Item>
                    <Form.Item label='TG群链接'>
                        <Input placeholder='(选填)https://t.me/xxxx' value={tmpTokenInfo.tgLink} onChange={e => {
                            setTmpTokenInfo({ ...tmpTokenInfo, tgLink: e.target.value })
                        }} />
                    </Form.Item> */}
                </Form>
            </Modal>



        </div >
    );
}

export default SetConfigPage;