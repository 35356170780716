// import logo from './logo.svg';
import './App.css';
// import { useState } from 'react';
// import { Connection, PublicKey, clusterApiUrl, SystemProgram } from '@solana/web3.js'
// import { AnchorProvider, BN, Program, web3 } from '@project-serum/anchor'
// import { TOKEN_PROGRAM_ID } from '@solana/spl-token'
// import idl from './idl.json'
import { Buffer } from 'buffer';
import IndexPage from './pages/index.jsx';
import BurnTokenPage from './pages/burn.jsx';
import { Routes, Route } from 'react-router-dom'
// const { Keypair } = web3
// let mintAccount = Keypair.generate()
// let tokenAccount = Keypair.generate()
// const programID = new PublicKey('3FiKcXMazfdENKSiaRGKwzPLrYtCEhBSadxmxrAQd5aJ')

window.Buffer = Buffer
function App() {
  // // const [walletAddress, setWalletAddress] = useState('')

  // // const connectWallet = async () => {

  // //   if (window.solana && window.solana.isPhantom) {
  // //     const response = await window.solana.connect();
  // //     console.log(response.publicKey.toString())
  // //     setWalletAddress(response.publicKey.toString())
  // //   } else {
  // //     alert('请安装phantom钱包')
  // //   }
  // // }
  // // const getProvider = () => {
  // //   const connection = new Connection(clusterApiUrl('testnet'), 'confirmed')
  // //   const provider = new AnchorProvider(connection, window.solana, {
  // //     preflightCommitment: 'confirmed',
  // //     commitment: 'confirmed',
  // //   })
  // //   return provider
  // // }

  // // const createToken = async () => {
  // //   console.log(window.solana)
  // //   await connectWallet()

  // //   // 调用合约
  // //   const provider = getProvider()
  // //   const program = new Program(idl, programID, provider)

  // //   console.log(provider.wallet.publicKey.toString())
  // //   const tx = await program.rpc.create("FatToken", "FAT", new BN(1000), false, {
  // //     accounts: {
  // //       tokenAccount: tokenAccount.publicKey,
  // //       payer: provider.wallet.publicKey,
  // //       mint: mintAccount.publicKey,
  // //       treasury: provider.wallet.publicKey,
  // //       mintAuthority: provider.wallet.publicKey,
  // //       systemProgram: SystemProgram.programId,
  // //       rent: web3.SYSVAR_RENT_PUBKEY,
  // //       tokenProgram: TOKEN_PROGRAM_ID
  // //     },
  // //     signers: [tokenAccount, mintAccount]
  // //   })
  // //   console.log(tx)
  // }
  return (
    <div className="App">
     
      <IndexPage />
      {/* <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <div>
          walletAddress: {walletAddress}
        </div>
        <div>
          <button onClick={createToken}>调用create合约</button>
        </div>
      </header> */}
    </div>
  );
}

export default App;
