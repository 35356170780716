import { useEffect, useState } from 'react';
import { Col, Row, Divider, ConfigProvider, Dropdown, theme, Popover, message, Button, Space } from 'antd'
import {
    ProConfigProvider,
    ProLayout,
} from '@ant-design/pro-components'
import { LogoutOutlined, CaretDownFilled, TranslationOutlined, InfoCircleFilled, QuestionCircleFilled, GithubFilled } from '@ant-design/icons'
import { css } from '@emotion/css';
import CreateSolanaTokenPage from './sol-create.jsx';
import SetConfigPage from './set-config.jsx';
import { setWallet } from '../store/network.js'
import { useSelector, useDispatch } from 'react-redux';
import { getShortAddrss } from '../utils/tool.js';
import { useTranslation } from 'react-i18next';
import { Routes, Route } from 'react-router-dom'
import BurnTokenPage from './burn.jsx'
import TransferPage from './transfer.jsx';
import TxPage from './tx.jsx';

const MenuCard = () => {
    const { token } = theme.useToken();
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Divider
                style={{
                    height: '1.5em',
                }}
                type="vertical"
            />
            <Popover
                placement="bottom"
                overlayStyle={{
                    width: 'calc(100vw - 24px)',
                    padding: '24px',
                    paddingTop: 8,
                    height: '307px',
                    borderRadius: '0 0 6px 6px',
                }}
                content={
                    <div style={{ display: 'flex', padding: '32px 40px' }}>
                        <div style={{ flex: 1 }}>
                            {/* <List title="金融解决方案" />
                            <List
                                title="其他解决方案"
                                style={{
                                    marginBlockStart: 32,
                                }}
                            /> */}
                        </div>

                        <div
                            style={{
                                width: '308px',
                                borderInlineStart: '1px solid ' + token.colorBorder,
                                paddingInlineStart: 16,
                            }}
                        >
                            <div
                                className={css`
                  font-size: 14px;
                  color: ${token.colorText};
                  line-height: 22px;
                `}
                            >
                                热门产品
                            </div>
                            {new Array(3).fill(1).map((name, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={css`
                      border-radius: 4px;
                      padding: 16px;
                      margin-top: 4px;
                      display: flex;
                      cursor: pointer;
                      &:hover {
                        background-color: ${token.colorBgTextHover};
                      }
                    `}
                                    >
                                        <img src="https://gw.alipayobjects.com/zos/antfincdn/6FTGmLLmN/bianzu%25252013.svg" />
                                        <div
                                            style={{
                                                marginInlineStart: 14,
                                            }}
                                        >
                                            <div
                                                className={css`
                          font-size: 14px;
                          color: ${token.colorText};
                          line-height: 22px;
                        `}
                                            >
                                                Ant Design
                                            </div>
                                            <div
                                                className={css`
                          font-size: 12px;
                          color: ${token.colorTextSecondary};
                          line-height: 20px;
                        `}
                                            >
                                                杭州市较知名的 UI 设计语言
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                }
            >
                <div
                    style={{
                        color: token.colorTextHeading,
                        fontWeight: 500,
                        cursor: 'pointer',
                        display: 'flex',
                        gap: 4,
                        paddingInlineStart: 8,
                        paddingInlineEnd: 12,
                        alignItems: 'center',
                    }}
                    className={css`
            &:hover {
              background-color: ${token.colorBgTextHover};
            }
          `}
                >
                    <span> 企业级资产中心</span>
                    <CaretDownFilled />
                </div>
            </Popover>
        </div>
    );
};




function IndexPage() {

    const walletAddress = useSelector((state) => state.network.wallet)
    const dispatch = useDispatch()
    const [pathname, setPathname] = useState('');

    const [messageApi, contextHolder] = message.useMessage()
    const [collapsed, setCollapsed] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    const { t, i18n } = useTranslation()

    useEffect(() => {
        // 尝试登录
        // connectWallet()
        if (document.body.clientWidth < 768) {
            setCollapsed(true)
            setIsMobile(true)
        }
    }, [])

    useEffect(() => {

    }, [])

    useEffect(() => {
        const h = window.location.hash
        if (h.indexOf('#') === 0) {
            setPathname(h.replace('#', ''))
        }
    }, [])

    useEffect(() => {

        // 跳转到对应的路由
        if (window.location.hash !== `#${pathname}`) {
            window.location.hash = pathname;
        }

    }, [pathname])

    const connectWallet = async () => {

        if (window.solana && window.solana.isPhantom) {
            try {
                const response = await window.solana.connect();
                dispatch(setWallet(response.publicKey.toString()))
            } catch (e) {
                if (e.code === 4001) {
                    messageApi.error(t('已拒绝连接钱包'))
                } else {
                    messageApi.error(e.message || '连接钱包失败')
                }
            }

        } else {
            messageApi.info(t('请安装phantom钱包'))
        }
    }

    const disconnectWallet = async () => {
        if (window.solana && window.solana.isPhantom) {
            const response = await window.solana.disconnect();
            dispatch(setWallet(''))
        } else {
            messageApi.info(t('请安装phantom钱包'))
        }
    }

    return <div
        id="test-pro-layout"
        style={{
            height: '100vh',
            overflow: 'auto',
        }}
    >
        {contextHolder}
        <ProConfigProvider hashed={false}>
            <ConfigProvider getTargetContainer={() => {
                return document.getElementById('test-pro-layout') || document.body;
            }}>
                <ProLayout
                    fixedHeader={true}
                    title="共创社区"
                    logo="https://token-monitor.s3.amazonaws.com/1728539649502cropped-1728539649478.png"
                    prefixCls="my-prefix"
                    layout='mix'

                    location={{
                        pathname
                    }}
                    contentStyle={{
                        backgroundColor: '#ffffff'
                    }}
                    avatarProps={{
                        src: 'https://token-monitor.s3.amazonaws.com/1709534588493cropped-1709534588471.png',
                        size: 'small',
                        title: getShortAddrss(walletAddress),
                        render: (props, dom) => {
                            return (
                                <>

                                    {/* <Dropdown menu={{
                                        items: [
                                            {
                                                key: 'en',
                                                label: 'English'
                                            },
                                            {
                                                key: 'zh',
                                                label: '简体中文'
                                            }
                                        ],
                                        onClick: ({ key }) => {
                                            console.log(key)
                                            i18n.changeLanguage(key)
                                            // setLocale(key, true)

                                        }
                                    }}>
                                        <TranslationOutlined style={{ fontSize: '20px', marginRight: '10px' }} />
                                    </Dropdown> */}

                                    {/* {walletAddress ? <Dropdown
                                        menu={{
                                            items: [
                                                {
                                                    key: 'logout',
                                                    icon: <LogoutOutlined />,
                                                    label: t('退出登录'),
                                                },
                                            ],
                                            onClick: ({ key }) => {
                                                if (key === 'logout') {
                                                    disconnectWallet()
                                                }

                                            }
                                        }}

                                    >
                                        {dom}
                                    </Dropdown> : <div>
                                        <Button shape='round' onClick={connectWallet} type='primary'>{t('连接钱包')} </Button>
                                    </div>} */}
                                </>
                            );
                        },
                    }}

                    menuRender={(props, defaultDom) => (
                        <>
                            {defaultDom}
                        </>
                    )}

                    // menuRender={(props) => {
                    //     return <div>aaaa</div>
                    // }}
                    // actionsRender={(props) => {
                    //     if (props.isMobile) return [];
                    //     if (typeof window === 'undefined') return [];
                    //     return [
                    //         props.layout !== 'side' && document.body.clientWidth > 1400 ? (
                    //             <InfoCircleFilled />
                    //         ) : undefined,
                    //         <InfoCircleFilled key="InfoCircleFilled" />,
                    //         <QuestionCircleFilled key="QuestionCircleFilled" />,
                    //         <GithubFilled key="GithubFilled" />,
                    //     ];
                    // }}

                    // menuDataRender={(menuData) => {
                    //     return menuData;

                    // }}
                    menu={{
                        request: async () => {
                            // await waitTime(2000);
                            return [
                                {
                                    path: '/',
                                    name: t('修改配置'),
                                    locale: true,
                                    exact: true
                                },
                                // {
                                //     path: '/',
                                //     name: t('创建代币'),
                                //     locale: true,
                                //     exact: true
                                // },

                                // {
                                //     path: '/tools',
                                //     name: t('工具'),
                                //     routes: [
                                //         {
                                //             path: '/burn',
                                //             name: t('销毁代币'),
                                //             exact: true
                                //         },
                                //         {
                                //             path: '/transfer',
                                //             name: t('批量转账'),
                                //             exact: true
                                //         },
                                //         {
                                //             path: '/tx',
                                //             name: t('刷交易量工具'),
                                //             exact: true
                                //         },
                                //     ]
                                // },
                                // {
                                //     path: '/',
                                //     name: '欢迎',
                                //     routes: [
                                //         {
                                //             path: '/welcome',
                                //             name: 'one',
                                //             routes: [
                                //                 {
                                //                     path: '/welcome/welcome',
                                //                     name: 'two',
                                //                     exact: true,
                                //                 },
                                //             ],
                                //         },
                                //     ],
                                // },
                                // {
                                //     path: '/demo',
                                //     name: '例子',
                                // },
                            ];
                        },
                    }}
                    // collapsed={true}
                    // defaultCollapsed={true}
                    collapsed={collapsed}
                    onPageChange={e => {
                        console.log(e)
                        if (isMobile) {
                            setCollapsed(true)
                        }
                    }}
                    onCollapse={e => {
                        setCollapsed(e)
                    }}
                    menuItemRender={(item, dom) => (
                        <div
                            onClick={() => {
                                setPathname(item.path || '/');
                            }}
                        >
                            {dom}
                        </div>
                    )}

                    headerTitleRender={(logo, title, _) => {
                        const defaultDom = (
                            <a>
                                {logo} &nbsp;
                                {title}
                            </a>
                        );
                        if (typeof window === 'undefined') return defaultDom;
                        if (document.body.clientWidth < 1400) {
                            return defaultDom;
                        }
                        if (_.isMobile) return defaultDom;
                        return (
                            <>
                                {defaultDom}
                                {/* <MenuCard /> */}
                            </>
                        );
                    }}
                    // menuFooterRender={(props) => {
                    //     if (props?.collapsed) return undefined;
                    //     return (
                    //         <div
                    //             style={{
                    //                 textAlign: 'center',
                    //                 paddingBlockStart: 12,
                    //             }}
                    //         >
                    //             <div>{t('其余功能暂未开放')}</div>
                    //         </div>
                    //     );
                    // }}
                    onMenuHeaderClick={(e) => console.log(e)}
                // menuItemRender={(item, dom) => (
                //     <div
                //         onClick={() => {
                //             setPathname(item.path || '/welcome');
                //         }}
                //     >
                //         {dom}
                //     </div>
                // )}


                >

                    <Routes>
                        {/* <Route path="/" element={<CreateSolanaTokenPage />} /> */}
                        <Route path="/" element={<SetConfigPage />} />
                        {/* <Route path="/burn" element={<BurnTokenPage />} />
                        <Route path="/transfer" element={<TransferPage />} />
                        <Route path="/tx" element={<TxPage />} /> */}
                    </Routes>

                    {/* <CreateSolanaTokenPage /> */}
                </ProLayout>

            </ConfigProvider>
        </ProConfigProvider >


    </div >
}

export default IndexPage;